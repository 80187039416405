import { useEffect, useState } from "react";

// Define a type for the possible platforms
export type OperatingSystem = "Windows" | "Mac" | "iOS" | "Android" | "Linux" | null;
export type DeviceType = "Desktop" | "Mobile" | null;

const usePlatformDetector = (): {
  platform: OperatingSystem;
  device: DeviceType;
} => {
  // State to store the detected platform and device type
  const [platform, setPlatform] = useState<{
    platform: OperatingSystem;
    device: DeviceType;
  }>({
    platform: null,
    device: null,
  });

  useEffect(() => {
    // Function to detect the platform and device type based on navigator.userAgent
    const detectPlatform = (): {
      platform: OperatingSystem;
      device: DeviceType;
    } => {
      const userAgent = window.navigator.userAgent.toLowerCase();

      if (userAgent.includes("win")) {
        return { platform: "Windows", device: "Desktop" };
      } else if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
        return { platform: "iOS", device: "Mobile" };
      } else if (userAgent.includes("mac")) {
        return { platform: "Mac", device: "Desktop" };
      } else if (userAgent.includes("android")) {
        return { platform: "Android", device: "Mobile" };
      } else if (userAgent.includes("linux")) {
        return { platform: "Linux", device: "Desktop" };
      }

      // Default to null if the platform is not recognized
      return { platform: null, device: null };
    };

    // Set the initial platform and device type
    setPlatform(detectPlatform());

    // Event listener for orientation changes (useful for mobile devices)
    const handleOrientationChange = (): void => {
      setPlatform(detectPlatform());
    };

    // Attach the event listener
    window.addEventListener("orientationchange", handleOrientationChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []); // Empty dependency array ensures the effect runs once on mount

  // Return the detected platform and device type
  return platform;
};

export default usePlatformDetector;