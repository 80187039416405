// Changes the download button link and icon based on the user's platform.
import usePlatformDetector from "@/hooks/usePlatformDetector";
import { Entry } from "contentful";
import { useRef } from "react";
import Button from "../atoms/button";
import Image from "next/image";
import { AlcatrazLocales } from "@/config/supported-countries";
import { useRouter } from "next/router";
import { getRouteDetails } from "@/utils/helpers";

const downloadMT5Map = new Map<AlcatrazLocales, string>([
  ["en", "Download MT5"],
  ["ar", "تنزيل MT5"],
  ["bn", "MT5 ডাউনলোড করুন"],
  ["es", "Descargar MT5"],
  ["fr", "Télécharger MT5"],
  ["hi", "MT5 डाउनलोड करें"],
  ["id", "Unduh MT5"],
  ["ko", "MT5 다운로드"],
  ["ms", "Muat turun MT5"],
  ["pt", "Baixar MT5"],
  ["th", "ดาวน์โหลด MT5"],
  ["tl", "I-download ang MT5"],
  ["tr", "MT5'i indir"],
  ["vi", "Tải xuống MT5"],
  ["zh-HK", "下載 MT5"],
  ["zh-MY", "下载 MT5"],
]);

const platformMap: {
  [key: string]: {
    href: string;
    SVG: any;
    className?: string;
  };
} = {
  Windows: {
    href: "https://download.mql5.com/cdn/web/metaquotes.ltd/mt5/mt5setup.exe",
    SVG: "static-windows",
    className: "accent-stroke-white",
  },
  Mac: {
    href: "https://download.mql5.com/cdn/web/metaquotes.ltd/mt5/MetaTrader5.pkg.zip",
    SVG: "static-apple",
  },
  Linux: {
    href: "https://www.metatrader5.com/en/terminal/help/start_advanced/install_linux",
    SVG: "static-linux",
  },
  iOS: {
    href: "https://download.mql5.com/cdn/mobile/mt5/ios",
    SVG: "static-apple",
  },
  Android: {
    href: "https://download.mql5.com/cdn/mobile/mt5/android",
    SVG: "static-play-store",
  },
};

const AdaptiveMT5DownloadButton = ({
  data,
  debug,
  className,
  options = data?.fields?.options || [],
  ...props
}: {
  data: Entry<any>;
  debug?: any;
  className?: string;
  options?: {
    platforms: ("ios" | "android" | "desktop")[];
  };
}) => {
  const environment = usePlatformDetector();
  const platformData = platformMap[environment.platform ?? "Windows"];

  const router = useRouter();
  const { locale } = getRouteDetails(router, true);

  const downloadMT5Text = downloadMT5Map.has(locale as AlcatrazLocales)
    ? downloadMT5Map.get(locale as AlcatrazLocales)
    : downloadMT5Map.get("en");

  const ref = useRef(null);

  if (platformData) {
    return (
      <div
        {...props}
        className="relative mt-2 flex first:mt-0 md:mt-0 w-[160px] h-[49px]"
      >
        {debug}

        {environment.device === "Desktop" && options?.platforms?.includes("desktop") && (
          <Button
            title={downloadMT5Text}
            // TODO: Fix the type error
            icon={platformData.SVG}
            variant="secondary"
            url={platformData.href}
          />
        )}

        {environment.device === "Mobile" &&
          environment.platform === "iOS" &&
          options?.platforms?.includes("ios") && (
            <a
              className="inline-block w-fit h-full"
              href={platformData.href}
              ref={ref}
              {...props}
            >
              <Image
                alt="Apple App Store"
                loading="lazy"
                width="160"
                height="49"
                src="https://cdn.cmcmarkets.com/neptune/images/app-store-ios.svg"
              />
            </a>
          )}

        {environment.device === "Mobile" &&
          environment.platform === "Android" &&
          options?.platforms?.includes("android") && (
            <a
              className="inline-block w-fit h-full"
              href={platformData.href}
              ref={ref}
              {...props}
            >
              <Image
                alt="Google Play Store"
                loading="lazy"
                width="160"
                height="49"
                src="https://cdn.cmcmarkets.com/neptune/images/app-store-android.svg"
              />
            </a>
          )}
      </div>
    );
  }
};

export default AdaptiveMT5DownloadButton;
